<template>
  <div class="search-page page-layout wave-filigrana detail-page details">
    <HeaderIntroImage underlined backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container">
      <div class="step">
        

    
        <div  class="search-form">
          
          <br>
          <form @submit.prevent="searchData">
            <input type="search" id="search" name="search" placeholder="Free search..." v-model="searchField">
          </form>
          
          <br>
          
      
          <button @click="searchData" class="btn slim">
            Apply
          </button> 

          <button @click="resetData" class=" btn  slim reset">
            Reset
          </button>

        </div>

        <div class="content" v-if="this.data && this.data.tot !== 0 ">
          <div class="item" v-for="(d, index) in this.data.data" :key="+index">
            <router-link :to="d.url">

              <p class="label type">{{d.type_name}}</p>

              <h4 v-html="d.title"></h4>
              <div class="desc" v-html="d.text"></div>
            </router-link>
          </div>
        </div>
        <div class="content" v-else>
            <h3>No result</h3>
        </div>
      </div>


    </div>



  </div>
</template>

<script>
  import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'

  export default {
    name: 'search',
    components: {
      HeaderIntroImage,
    },
    props: {},
    data: () => {
      return {
        searchField: '',
        linkToFetch: '',
        data: [],
        pageData: {
          attributes: {
            title: "Search Results",
            tag: "",
            subtitle: ""
          }
        },
      }
    },
    computed: {},
    methods: {
      resetData(){
        this.searchField = ""
        this.data = []
      },
      searchData() {
        console.log('searchData');
        this.fetchData(this.searchField)

        this.$router.push({ path: '/search', query: { value: this.searchField } })


      },
      fetchData(value) {
        //fetch it
        fetch(`${process.env.VUE_APP_ENDPOINT}api/search/${value}`)
          .then(res => res.json())
          .then((json) => {
            this.data = json
            console.log('this.data', this.data);
          })
      },
      searchByUrl() {
        //get value from store
        this.fetchData(this.$route.query.value)
        console.log('this.$route.query.value - ', this.$route.query.value);
      }
    },
    mounted() {
      //se ci arrivo cercando da header 
      if (this.$route.query.value != null) {
        console.log('search by url');
        this.searchByUrl()
        this.searchField = this.$route.query.value
      }
    },
    watch: {

    }
  }
</script>

<style lang="scss">
  .search-form {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

form {
  max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
    input {
      width: 100%;
      font-size: 18px;
      max-width: 400px;
      margin: 0px auto;
    }

    button.btn.slim {
      width: 100%;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      color: #fff;

    }

    button.btn.slim.reset {
      background: #F3F3F3;
      color: #333;
      transition: 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
    }

  }

  .search-page.page-layout {
    header.header-intro {
      position: relative;
      background-color: #071152;
      padding: calc(3rem + 60px) 0rem 3rem 0rem;
    }
  }

  .search-page .content {
    padding: 4rem 0rem;

    .item {
      margin-bottom: 3rem;
      padding-bottom: 35px;
      border-bottom: 1px solid #C4C4C4;
    }

    a:not(.plain) {
      background: transparent;
      text-decoration: none;
      display: inline;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: inherit;
    }

    .type {
      color: #0000B4;
      font-size: 16px;
      margin-bottom: 0;
    }

    p {
      -webkit-line-clamp: 3;
    }

    h4 {
      color: #333333;
      margin-bottom: 0;
      margin-top: 0;
    }

    .desc {
      max-height: 280px;
      position: relative;
      overflow: hidden;
      &:after {
        content: "";
        background:linear-gradient(rgba(255, 255, 255, 0), #fff) ;
        width: 100%;
        height: 70px;
        z-index: 2;
      position: absolute;
      bottom: 0;
      }
    }
  }

  @media (min-width:992px) {
    .search-page.page-layout {
      header.header-intro {
        padding: calc(5rem + 60px) 0rem 10rem 0rem;
      }

      .item {
            cursor: pointer;
            position: relative;
            &:after {
              position: absolute;
              right: 50px;
              bottom: 30px;
              width: 40px;
              content: url("../assets/arrow.svg");
              transition: 0.2s ease;
            }
            &:hover:after {
              transform: translateX(20px);
              
            }
            &:hover h4{
              color: #0000b4;
            }

            >a {
              padding-right: 200px;
                  display: block;

            }
      }
    }

    .search-page .backstep+.container .step:first-child {
      margin-bottom: 0rem;
      padding-top: 4em;
    }

    .search-form {
      width: 100%;
      max-width: none;
      flex-direction: row;

       form {
    max-width: none;
    margin-left: 0;
    margin-right: 20px;
      }

      br {
        display: none;
      }

      input {
        margin-right: 30px;
        max-width: 670px;
        padding: 13px 1em;
        border-radius: 0px;
        background-color: #fff;
      }

      button.btn.slim {
        max-width: 130px;
        margin-top: 0;
        margin-left: 4px;
        margin-right: 4px;
        top: 0;
        color: #fff;
      }

      button.btn.reset {
        color: #333;
      }

    }

  }
</style>